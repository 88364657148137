"use strict";

document.addEventListener( 'DOMContentLoaded', function () {

    // * * * * * * * * * * * * * * * * * * * * * * * * * 
    // * hamburger
    // *
    // *

    const menu = document.querySelectorAll(".js-menu-btn");
    const navigation =  document.querySelector(".nav");

    if (menu !== null) {
        for (let i = 0; i < menu.length; i++) {
            menu[i].addEventListener("click", function () {
                navigation.classList.toggle("nav__show");
               
                let expanded = this.getAttribute('aria-expanded');
                if (expanded === 'false') {
                    this.setAttribute('aria-expanded', 'true');
                    this.classList.add('open');
                } else {
                    this.setAttribute('aria-expanded', 'false');
                    this.classList.remove('open');
                }
            });
        }
    }

    if (menu !== null) {
        for (let i = 0; i < menu.length; i++) {
            menu[i].addEventListener("keydown", function (event) {
                if (event.keyCode === 13 || event.keyCode === 32 || event.key === "Escape") {
                    event.preventDefault();
                    navigation.classList.toggle("nav__show");
        
                    let expanded = this.getAttribute('aria-expanded');
                    if (expanded == 'false') {
                        this.setAttribute('aria-expanded', 'true');
                        this.classList.add('open');
                    } else {
                        this.setAttribute('aria-expanded', 'false');
                        this.classList.remove('open');
                    }
                }
            });
        }
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * mobile menu
    // *
    // *

    const nav = document.querySelectorAll(".js-subnav");
    
    if (nav !== null) {
        for (var i=0; i < nav.length; i++) {
            nav[i].onclick = function(e) {
                this.parentNode.parentNode.classList.toggle("active");
            }
        }
    }



    
    // * * * * * * * * * * * * * * * * * * * * * * * * * 
    // * splide heroSlider
    // *
    // *

    const heroSlider = document.getElementsByClassName('js-hero-slider');
    
    if (heroSlider !== null) {
        for (let i = 0; i < heroSlider.length; i++) {
            new Splide(heroSlider[i], {
                type            : 'fade',
                pauseOnHover    : true,
                rewind          : true,
                lazyLoad        : 'nearby'
            }).mount();
        }
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * splide slider
    // *
    // *

    const textSlider = document.getElementsByClassName('js-text-slider');
    
    if (textSlider !== null) {
        for (let i = 0; i < textSlider.length; i++) {
            new Splide(textSlider[i], {
                type            : 'slide',
                rewind          : true,
                arrows          : true,
                pagination      : true,
                lazyLoad        : 'nearby',
                perPage         : 1,
                gap             : '3rem',
                breakpoints: {
                    768: {
                        perPage: 1,
                    }
                }
            }).mount();
        }
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * * 
    // * splide instagram
    // *
    // *
    
    const instagramSlider = document.querySelectorAll(".js-instagram-slider");
    if (instagramSlider !== null) {
        for (let i = 0; i < instagramSlider.length; i++) {
            // splide init
            const splide = new Splide(instagramSlider[i], {
                type            : 'loop',
                rewind          : true,
                autoplay        : true,
                arrows          : false,
                pagination      : false,
                lazyLoad        : 'nearby',
                perPage         : 3,
                perMove         : 1,
                speed           : 1000,
                interval        : 2000,
                gap             : '2rem',
                breakpoints: {
                    768: {
                        perMove : 1,
                        perPage: 2,
                    },
                    576: {
                        perMove : 1,
                        perPage : 1,
                    },
                }
            }).mount();
        }
    };



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * scrollToTop
    // *
    // *
    
    const scrolltotop  = document.querySelector(".js-scrolltop");

    if (scrolltotop !== null) {
        // show and hide scrollToTop btn on scroll
        document.addEventListener('scroll', function(e) {
            if (window.scrollY > 300) {
                scrolltotop.classList.add("show");
            } else {
                scrolltotop.classList.remove("show");
            }
        });

        // onclick scroll to top
        scrolltotop.onclick = function(e){
            e.preventDefault();
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }

        // onkeydown scroll to top or bottom
        scrolltotop.addEventListener("keydown", function (event) {
            if (event.keyCode === 13 || event.keyCode === 32) {
                event.preventDefault();
    
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }   
        });
    }

    // detect footer on scroll and add class
    window.addEventListener('scroll', function() {
        const footer = document.querySelector(".footer");
        const footerTop = footer.offsetTop;

        if (window.pageYOffset + window.innerHeight >= footerTop) {
            document.querySelector(".js-scrolltop").classList.add("light");
        } else {
            document.querySelector(".js-scrolltop").classList.remove("light");
        }
    });
    
    // unbedingt ueberdenken und vor allem kommentieren :-)
    const ctaBtn = document.querySelector('.cta');
    const footerBtn = document.querySelector('.footer');

    window.addEventListener('scroll', function() {
        const footerTop = footerBtn.offsetTop;
        const scrollPosition = window.pageYOffset + window.innerHeight;
        
        if (scrollPosition >= footerTop) {
            ctaBtn.style.display = "none";
        } else {
            ctaBtn.style.display = "block";
        }
    });
      


    // * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
    // * anchor nav scrollTo
    // *
    // *
    
    // get browser width
    let browserWidth = window.innerWidth;
    let anchorBox = document.querySelector('.js-anchor-nav');
    let anchorBoxSticky = document.querySelector('.js-anchor-sticky');

    // check if anchor modul is set to sticky
    if (anchorBoxSticky !== null) {
        window.addEventListener('scroll', function(e) {
            // get pos from anchorbox
            let pos = Math.round(anchorBox.getBoundingClientRect().y);

            if (pos <= 0) {
                anchorBox.classList.add("stick");
            } else {
                anchorBox.classList.remove("stick");
            }
        });
    }

    // check if anchor modul is set
    if (anchorBox !== null) {
        // needed for sticky anchor to calculate offsetTop
        anchorBoxHeight = anchorBox.offsetHeight;

        if (browserWidth > 1280) {
            anchorBoxHeight = anchorBoxHeight;
        } else {
            anchorBoxHeight = 0;
        }

        const anchors = document.querySelectorAll(".js-scrollTo"); 
        
        for (const anchor of anchors) {
            anchor.addEventListener("click", clickHandler);
        }

        function clickHandler(e) {
            e.preventDefault();
        
            let href = this.getAttribute("href").split("#");
            let newAnchor = "#my" + href[1]
            let offsetTop = document.querySelector(newAnchor).offsetTop - anchorBoxHeight;

            scroll({
                top: offsetTop,
                behavior: "smooth"
            });
        }
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
    // * hash link jump to content
    // *
    // *

    window.addEventListener('load', function () {
        let getHash = window.location.hash;
        let elementExists = document.getElementById("my" + getHash.slice(1));

        if (getHash !== null && elementExists !== null) {
            let name = "#my" + getHash.slice(1);
            let offsetTop = document.querySelector(name).offsetTop - 60;
        
            scroll({
                top: offsetTop,
                behavior: "smooth"
            });
            
        }
    }, false);


    
    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * modal window
    // *
    // *

    let modalWindow = document.querySelector(".js-modal");
    let modalOpen = document.querySelector(".js-modal-btn-open");
    let modalClose = document.querySelector(".js-modal-btn-close");
    
    /* onclick show modal window */
    if (modalOpen !== null) {
        modalOpen.onclick = function() {
            modalWindow.style.display = "block";
            modalClose.focus();
        }
    }
    
    /* close modal window */
    if (modalClose !== null) {
        modalClose.onclick = function() {
            modalWindow.style.display = "none";
        }
    }
    
    /* clicks anywhere outside close modal window */
    window.onclick = function(event) {
        if (event.target == modalWindow) {
            modalWindow.style.display = "none";
        }
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * accordion open every tab
    // *
    // *

    const title = document.querySelectorAll(".js-accordion-title");

    if (title !== null) {
        for (var i=0; i < title.length; i++) {
            title[i].onclick = function(e) {
                this.classList.toggle("open");
                this.nextElementSibling.classList.toggle("open");

                let expanded = this.getAttribute('aria-expanded');
        
                if (expanded == 'false') {
                    this.setAttribute('aria-expanded', 'true')
                } else {
                    this.setAttribute('aria-expanded', 'false')
                }
            }
            title[i].addEventListener("keydown", function (event) {
                if (event.keyCode === 13 || event.keyCode === 32) {
        
                    event.preventDefault();
        
                    this.classList.toggle("open");
                    this.nextElementSibling.classList.toggle("open");
        
                    let expanded = this.getAttribute('aria-expanded');
        
                    if (expanded == 'false') {
                        this.setAttribute('aria-expanded', 'true')
                    } else {
                        this.setAttribute('aria-expanded', 'false')
                    }
                }
            });
        }
    }


    
    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * open external links in new tab
    // *
    // *

    function setExternalLinkTargets() {
        const links = document.querySelectorAll('a');
      
        for (const link of links) {
            if (link.hostname !== location.hostname && !link.href.startsWith('javascript:')) {
                link.target = '_blank';
            }
        }
    }  
    setExternalLinkTargets();




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * simpleParallax
    // *
    // *

    let parallaxImages = document.getElementsByClassName('js-parallax');
    new simpleParallax(parallaxImages, {
        delay: 1,
        scale: 1.25
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * aos
    // *
    // *

    AOS.init({
        offset: 200,
        duration: 700,
        delay: 100,
        disable: 'mobile',
        easing: 'ease-out-back'
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * lottie animations on hover
    // *
    // *

    const lottieItems = document.querySelectorAll('.iconList__item');
    const lottieAnimations = [];

    if (lottieItems !== null) {
        lottieItems.forEach((item,index) => {
            lottieAnimations[index] = item.querySelector("lottie-player");
            item.addEventListener("mouseenter", () => {
                lottieAnimations[index].play();
            });
            item.addEventListener("mouseleave", () => {
                lottieAnimations[index].stop();
            });
        });
    }

});
